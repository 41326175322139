.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  @apply min-w-[178px] rounded border border-neutral-700 p-0 shadow-none;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  @apply rounded-none px-16 py-10 font-inter text-12 font-normal leading-18;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:not(:last-child),
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-item:not(:last-child) {
  @apply border-b border-neutral-700;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active {
  @apply !bg-gray-300;
}

.ant-dropdown-trigger {
  @apply flex h-full items-center justify-center;
}

.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: unset;
}
