.ant-input-affix-wrapper-lg {
  @apply rounded-lg !px-12 !py-7;
}
.ant-input-prefix {
  @apply !me-0 !pr-10;
}
.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
  @apply border-helper-text;
}
.custom-input .ant-input:hover,
.ant-input-affix-wrapper:hover {
  /* @apply border-border bg-transparent; */
  @apply border-border;
}

.custom-input .ant-input[disabled] {
  @apply shadow-transparent;
}
.custom-input .ant-input:focus,
.custom-input .ant-input:focus-within {
  @apply border-primary-300 shadow-input-field-focus-shadow;
}
/* textarea */
.ant-input-textarea-allow-clear {
  @apply border-none;
}
.ant-input-clear-icon {
  @apply !right-4 !top-1;
}
textarea.ant-input {
  @apply !w-full !rounded-lg !border !border-solid !border-gray-300 !px-12 !pr-30 !py-7 !text-16 !font-normal !leading-24 !text-gray-800 !shadow-input-field-shadow placeholder:text-16 placeholder:text-gray-500-base;
}
.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper
  > textarea.ant-input:focus {
  @apply !border !border-solid !border-primary-300 !shadow-input-field-focus-shadow;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:focus-within {
  @apply !border !border-solid !border-primary-300 !shadow-input-field-focus-shadow;
}

.otp-input:focus-visible {
  @apply !border-primary-300;
}
.ant-input-disabled,
.ant-input[disabled],
.ant-input-disabled:hover,
.ant-input[disabled]:hover {
  @apply border-gray-300 bg-gray-50 text-gray-500-base shadow-input-field-shadow;
}
.ant-input-affix-wrapper-disabled,
.ant-input-affix-wrapper[disabled],
.ant-input-affix-wrapper-disabled:hover,
.ant-input-affix-wrapper[disabled]:hover {
  @apply border-gray-300 bg-gray-50 text-gray-500-base shadow-input-field-shadow;
}
.general-settings .ant-input-affix-wrapper-disabled,
.general-settings .ant-input-affix-wrapper[disabled],
.general-settings .ant-input-affix-wrapper-disabled:hover,
.general-settings .ant-input-affix-wrapper[disabled]:hover,
.general-settings .ant-input-disabled,
.general-settings .ant-input[disabled] {
  @apply !border-gray-300 !bg-gray-50 !text-gray-500-base shadow-input-field-shadow;
  /* Safari fix for input field value color */
  -webkit-text-fill-color: #667085 !important;
}
.general-settings .ant-input-affix-wrapper-disabled .ant-input-suffix .anticon,
.general-settings
  .ant-input-affix-wrapper-disabled
  .ant-input-suffix
  .anticon:hover {
  @apply !text-gray-500-base;
}
.general-settings .ant-input-affix-wrapper .anticon {
  @apply !text-gray-800;
}
