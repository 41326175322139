@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: "Inter", sans-serif;
    @apply bg-white;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

/* custom-scroll */
/* width */
.theme-custom-scroll::-webkit-scrollbar {
  width: 4px;
  background-color: #fff0;
}

/* Track */
.theme-custom-scroll::-webkit-scrollbar-track {
  border-radius: 19px;
  background-color: #e8f1f6;
}

/* Handle */
.theme-custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 21px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #98a2b3;
}

/* Loader */
.theme-custom-loader {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #00000014;
  z-index: 9999;
  overflow: auto;
}
/* Add this to your existing styles */
.shimmer {
  background: linear-gradient(
    90deg,
    #c8dcea 0%,
    rgba(200, 220, 234, 0.5) 50%,
    /* Adjust the alpha value */ #c8dcea 100%
  );
  background-size: 300% 100%;
  animation: shimmerAnimation 2.5s infinite alternate-reverse linear;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2e4e88;
  border-color: #2e4e88;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #2e4e88;
  border-color: #2e4e88;
}

.no-cursor-text input {
  caret-color: transparent !important;
  cursor: pointer !important;
}

button:disabled {
  cursor: not-allowed !important;
  opacity: 0.6;
}

@keyframes shimmerAnimation {
  0% {
    background-position: -300% 0;
  }
  100% {
    background-position: 300% 0;
  }
}

label.ant-segmented-item.ant-segmented-item {
  @apply text-black;
}

label.ant-segmented-item.ant-segmented-item-selected {
  @apply bg-primary-50  text-blue-1;
}

.ant-segmented-thumb-motion-appear {
  @apply !bg-primary-50  !text-blue-1;
}
label.ant-segmented-item.ant-segmented-item-selected {
  @apply bg-primary-50  text-blue-1;
}
.ant-segmented .ant-segmented-item-label {
  @apply px-12 py-2;
}
.ant-segmented-group {
  @apply m-4;
}
.ant-modal-wrap.ant-modal-centered {
  @apply m-4;
}
