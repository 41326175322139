.ag-row {
  @apply border-l border-r !border-gray-300;
}

.ag-header-cell-text {
  @apply font-inter text-12 font-semibold leading-18 text-gray-500-base;
}

.ag-cell-value {
  @apply font-inter text-14 font-medium leading-20 text-gray-800;
}

.ag-header,
.ag-advanced-filter-header {
  @apply border-l border-r border-t !border-gray-300 !bg-gray-50;
}

.ag-root-wrapper {
  @apply !rounded-none !border-none;
}

.ag-theme-quartz {
  --ag-icon-font-code-grip: url("../../../../../public/assets/icons/drag.svg");
}

.ag-row-hover {
  @apply cursor-pointer;
}

.ag-cell-wrapper {
  @apply !h-full;
}
.ag-cell {
  @apply !h-full !border-0;
}

.ag-center-cols-viewport {
  @apply bg-[#FBFBFB];
}

.ag-header-cell-resize {
  @apply !hidden;
}
.ag-header:hover .ag-header-cell-resize {
  @apply !inline-flex;
}
